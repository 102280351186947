var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.loading)?_c('div',[(_vm.recalls.length > 0 || _vm.consultingAppointments.length > 0 || _vm.orders.length > 0)?[(_vm.orders.length > 0)?_c('material-card',{attrs:{"color":"primary","title":"Reservierungsanfragen"}},[_c('v-data-table',{attrs:{"headers":_vm.orderHeaders,"items":_vm.orders,"hide-default-footer":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-primary",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"linked-tr",on:{"click":function($event){return _vm.openOrder(item)}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.b_firstname)+" "+_vm._s(item.b_lastname))]),_c('td',_vm._l((item.orderproducts),function(orderproduct){return _c('div',{key:orderproduct.id},[_vm._v(" "+_vm._s(orderproduct.product.name)+" ")])}),0),_c('td',[_vm._v(_vm._s(_vm._f("euro")(item.total)))]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(item.created_at)))])])]}}],null,false,58743844)})],1):_vm._e(),(_vm.consultingAppointments.length > 0)?_c('material-card',{attrs:{"color":"primary","title":"Kundenberatungen"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.consultingAppointments,"hide-default-footer":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-primary",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm._f("datetime")(item.date)))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',[_vm._v(_vm._s(_vm._f("consultingAppointmentStatus")(item.status)))]),_c('td',[_c('router-link',{attrs:{"to":'/appointments/consulting/' + item.id}},[_c('v-btn',{attrs:{"small":"","color":"primary"}},[_c('v-icon',[_vm._v("search")])],1)],1)],1)])]}}],null,false,630017693)})],1):_vm._e(),(_vm.recalls.length > 0)?_c('material-card',{attrs:{"color":"primary","title":"Rückrufanfragen"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recalls,"hide-default-footer":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"font-weight-light text-primary",domProps:{"textContent":_vm._s(header.text)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(_vm._f("daytime")(item.time)))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',[_vm._v(_vm._s(_vm._f("recallStatus")(item.status)))]),_c('td',[_c('router-link',{attrs:{"to":'/appointments/recalls/' + item.id}},[_c('v-btn',{attrs:{"small":"","color":"primary"}},[_c('v-icon',[_vm._v("search")])],1)],1)],1)])]}}],null,false,3869293192)})],1):_vm._e()]:(_vm.$route.query.keyword)?_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Keine Ergebnisse gefunden.")]),_c('p',[_vm._v("Wir haben die Kundennamen, Telefonnummern, E-Mail-Addressen und Auftrags-IDs nach dem Suchbegriff durchsucht.")])]):_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Bitte geben Sie einen Suchbegriff ein.")])])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }