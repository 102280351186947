<template>
  <v-container fluid>
    <div
      v-if="!loading">
      <template v-if="recalls.length > 0 || consultingAppointments.length > 0 || orders.length > 0">
        <material-card
          v-if="orders.length > 0"
          color="primary"
          title="Reservierungsanfragen">
          <v-data-table
            :headers="orderHeaders"
            :items="orders"
            hide-default-footer>
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="font-weight-light text-primary"
                v-text="header.text"
              />
            </template>
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <tr
                class="linked-tr"
                @click="openOrder(item)">
                <td>{{ item.id }}</td>
                <td>{{ item.b_firstname }} {{ item.b_lastname }}</td>
                <td>
                  <div
                    v-for="orderproduct in item.orderproducts"
                    :key="orderproduct.id">
                    {{ orderproduct.product.name }}
                  </div>
                </td>
                <td>{{ item.total | euro }}</td>
                <td>{{ item.created_at | datetime }}</td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <material-card
          v-if="consultingAppointments.length > 0"
          color="primary"
          title="Kundenberatungen">
          <v-data-table
            :headers="headers"
            :items="consultingAppointments"
            hide-default-footer>
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="font-weight-light text-primary"
                v-text="header.text"
              />
            </template>
            <template
              slot="item"
              slot-scope="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.date | datetime }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.status | consultingAppointmentStatus }}</td>
                <td>
                  <router-link :to="'/appointments/consulting/' + item.id">
                    <v-btn
                      small
                      color="primary">
                      <v-icon>search</v-icon>
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <material-card
          v-if="recalls.length > 0"
          color="primary"
          title="Rückrufanfragen">
          <v-data-table
            :headers="headers"
            :items="recalls"
            hide-default-footer>
            <template
              slot="headerCell"
              slot-scope="{ header }">
              <span
                class="font-weight-light text-primary"
                v-text="header.text"
              />
            </template>
            <template
              slot="item"
              slot-scope="{ item }">
              <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.time | daytime }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.status | recallStatus }}</td>
                <td>
                  <router-link :to="'/appointments/recalls/' + item.id">
                    <v-btn
                      small
                      color="primary">
                      <v-icon>search</v-icon>
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </template>
      <div
        v-else-if="$route.query.keyword"
        class="text-center">
        <h3>Keine Ergebnisse gefunden.</h3>
        <p>Wir haben die Kundennamen, Telefonnummern, E-Mail-Addressen und Auftrags-IDs nach dem Suchbegriff durchsucht.</p>
      </div>
      <div
        v-else
        class="text-center">
        <h3>Bitte geben Sie einen Suchbegriff ein.</h3>
      </div>
    </div>
  </v-container>
</template>

<script>
import SearchAPI from '@/api/searches'

export default {
  data() {
    return {
      consultingAppointments: [],
      loading: false,
      recalls: [],
      orders: [],
      orderHeaders: [
        {
          sortable: false,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Kunde',
          value: 'name',
        },
        {
          sortable: false,
          text: 'Produkte',
          value: 'orderproducts',
        },
        {
          sortable: false,
          text: 'Gesamtbetrag',
          value: 'total',
        },
        {
          sortable: false,
          text: 'Vom',
          value: 'created_at',
        },
      ],
      headers: [
        {
          sortable: true,
          text: 'Kunde',
          value: 'name',
        },
        {
          sortable: true,
          text: 'Zeitraum',
          value: 'time',
        },
        {
          sortable: true,
          text: 'Telefonnummer',
          value: 'phone',
        },
        {
          sortable: true,
          text: 'Status',
          value: 'status',
        },
        {
          sortable: false,
          text: 'Aktionen',
          value: 'name',
        },
      ],
    }
  },
  created() {
    this.refreshTitle()
    this.loadSearch()
  },
  methods: {
    loadSearch(route) {
      const temporaryRoute = route || this.$route
      if (temporaryRoute.query.keyword) {
        this.loading = true
        SearchAPI.search(temporaryRoute.query.keyword).then((response) => {
          this.consultingAppointments = response.consulting_appointments
          this.recalls = response.recalls
          this.orders = response.orders
          this.loading = false
        })
      } else {
        this.consultingAppointments = []
        this.recalls = []
        this.orders = []
      }
    },
    openOrder(order) {
      this.$router.push(`/orders/${order.id}`)
    },
    refreshTitle(route) {
      const temporaryRoute = route || this.$route
      let title = 'Suchergebnisse'
      if (temporaryRoute.query.keyword) {
        title = title.concat(` für ${temporaryRoute.query.keyword}`)
      }
      this.$store.commit('app/setTitle', title)
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.refreshTitle(to)
    this.loadSearch(to)
    next()
  },
}
</script>
